<template>
  <div>
    <div class="page-section">
      <div :class="containerClass">
        <h3 class="text-center">LET'S GET YOU ON THE PATH TO SUCCESS</h3>
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center mr-2">
                <md-icon class="mr-2 setup-guide-icon">payment</md-icon>
                <div>
                  <strong>Payments</strong>
                  <p class="mb-0 text-muted">
                    Connect your Stripe account to start offering programs and taking online payments
                  </p>
                </div>
              </div>
              <b-btn variant="primary" class="setup-guide-btn btn-normal" disabled v-if="isLoading"
                ><i class="fas fa-circle-notch fa-spin"></i
              ></b-btn>
              <b-btn
                variant="success"
                class="setup-guide-btn btn-normal"
                @click.prevent
                v-else-if="setupStatuses.payment_method"
                ><md-icon>check</md-icon></b-btn
              >
              <router-link v-else class="btn btn-primary setup-guide-btn btn-normal" :to="{ name: 'manage-payment' }"
                >Add</router-link
              >
            </div>
            <hr />

            <!-- Programs -->
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center mr-2">
                <md-icon class="mr-2 setup-guide-icon">local_library</md-icon>
                <div>
                  <strong>Programs</strong>
                  <p class="mb-0 text-muted">Add programs you want to offer for your school</p>
                </div>
              </div>
              <b-btn variant="primary" class="setup-guide-btn btn-normal" disabled v-if="isLoading"
                ><i class="fas fa-circle-notch fa-spin"></i
              ></b-btn>
              <b-btn
                variant="success"
                class="setup-guide-btn btn-normal"
                @click.prevent
                v-else-if="setupStatuses.program"
                ><md-icon>check</md-icon></b-btn
              >
              <router-link v-else class="btn btn-primary setup-guide-btn btn-normal" :to="{ name: 'manage-programs' }"
                >Add</router-link
              >
            </div>
            <hr />

            <!-- Instructors -->
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center mr-2">
                <md-icon class="mr-2 setup-guide-icon">people_alt</md-icon>
                <div>
                  <strong>Instructors</strong>
                  <p class="mb-0 text-muted">Manage your school's instructors and assign classes to them</p>
                </div>
              </div>
              <b-btn variant="primary" class="setup-guide-btn btn-normal" disabled v-if="isLoading"
                ><i class="fas fa-circle-notch fa-spin"></i
              ></b-btn>
              <b-btn
                variant="success"
                class="setup-guide-btn btn-normal"
                @click.prevent
                v-else-if="setupStatuses.instructor"
                ><md-icon>check</md-icon></b-btn
              >
              <router-link
                v-else
                class="btn btn-primary setup-guide-btn btn-normal"
                :to="{ name: 'instructor-management-list' }"
                >Manage</router-link
              >
            </div>
            <hr />

            <!-- Classes -->
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center mr-2">
                <md-icon class="mr-2 setup-guide-icon">class</md-icon>
                <div>
                  <strong>Classes</strong>
                  <p class="mb-0 text-muted">Add class schedules of your programs for the students to register</p>
                </div>
              </div>
              <b-btn variant="primary" class="setup-guide-btn btn-normal" disabled v-if="isLoading"
                ><i class="fas fa-circle-notch fa-spin"></i
              ></b-btn>
              <b-btn
                variant="success"
                class="setup-guide-btn btn-normal"
                @click.prevent
                v-else-if="setupStatuses.classes"
                ><md-icon>check</md-icon></b-btn
              >
              <router-link
                v-else
                class="btn btn-primary setup-guide-btn btn-normal"
                :to="{ name: 'classes-management-list' }"
                >Add</router-link
              >
            </div>
            <hr />

            <!-- Products -->
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center mr-2">
                <md-icon class="mr-2 setup-guide-icon">local_mall</md-icon>
                <div>
                  <strong>Products</strong>
                  <p class="mb-0 text-muted">Manage the products you want to sell to your students</p>
                </div>
              </div>
              <b-btn variant="primary" class="setup-guide-btn btn-normal" disabled v-if="isLoading"
                ><i class="fas fa-circle-notch fa-spin"></i
              ></b-btn>
              <b-btn
                variant="success"
                class="setup-guide-btn btn-normal"
                @click.prevent
                v-else-if="setupStatuses.products"
                ><md-icon>check</md-icon></b-btn
              >
              <router-link
                v-else
                class="btn btn-primary setup-guide-btn btn-normal"
                :to="{ name: 'manage-product-list' }"
                >Manage</router-link
              >
            </div>
            <hr />

            <!-- Products -->
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center mr-2">
                <md-icon class="mr-2 setup-guide-icon">web</md-icon>
                <div>
                  <strong>Enrollment Catolog</strong>
                  <p class="mb-0 text-muted">
                    Copy the public enrollment catalog link to share to your community to make registrations easy
                  </p>
                </div>
              </div>
              <b-btn variant="primary" class="setup-guide-btn btn-normal" @click.prevent="copyEnrollmentLink"
                >Copy</b-btn
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { get } from 'lodash';

import Page from '../../../components/Page.vue';

export default {
  components: {},
  extends: Page,
  name: 'SchoolSetupGuide',

  data() {
    return {
      title: 'Setup Guide',

      isLoading: false,
      setupStatuses: {
        payment_method: false,
        program: false,
        classes: false,
        instructor: false,
        products: false,
      },
    };
  },

  computed: {
    ...mapGetters('navbarConfig', ['getLinkedEntity']),

    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Setup Guide', active: true },
      ];
    },
  },

  methods: {
    ...mapActions('school', ['getSchoolSetupGuideStatus']),

    async fetchSetupGuideStatus() {
      this.isLoading = true;
      try {
        this.setupStatuses = (await this.getSchoolSetupGuideStatus()).data;
      } catch (_err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },

    async copyEnrollmentLink() {
      try {
        await navigator.clipboard.writeText(get(this.getLinkedEntity, 'subdomain.subdomain_url', ''));
        this.makeToast({ variant: 'success', msg: 'Enrollment Portal Link Copied' });
      } catch ($e) {
        this.makeToast({ variant: 'danger', msg: 'Unable to copy.' });
      }
    },
  },

  async mounted() {
    this.fetchSetupGuideStatus();
  },
};
</script>
