<template>
  <div>
    <page-header
      :title="title"
      :breadcrumb="breadcrumb"
      :container-class="containerClass"
      info-text="List of emails sent to recipients."
    />
    <div class="page-section">
      <div :class="containerClass">
        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input class="w-lg-auto" :placeholder="'Search Logs'" v-model="searchTerm" @input="onSearch" />
                  <b-btn variant="flush" type="submit">
                    <md-icon v-text="'search'" />
                  </b-btn>
                </form>
              </div>
            </div>
          </div>

          <!-- Schools Table -->
          <b-table
            :fields="tableColumns"
            :items="logs"
            :busy="isLoading"
            head-variant="light"
            hover
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
          >
            <template #cell(email_title)="data"> {{ data.item.email_title }} </template>
            <template #cell(status)="data">
              <b-badge
                :class="`border border-${EMAIL_LOG_STATUSES_COLORS[data.item.status]} text-${
                  EMAIL_LOG_STATUSES_COLORS[data.item.status]
                }`"
                variant="none"
                >{{ $t(`emailLogStatuses.${data.item.status}`) }}
              </b-badge>
            </template>

            <template #cell(sent_to_email)="data"> {{ data.item.sent_to_email }} </template>
            <template #cell(sent_from_email)="data"> {{ data.item.sent_from_email }} </template>
            <template #cell(created_at)="data"> {{ formatDateTime(data.item.created_at) }} </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalLogs"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="log-table"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import PageHeader from '@/components/Ui/PageHeader.vue';
import Page from '@/components/Page.vue';
import Pagination from '@/components/Ui/Pagination.vue';
import MdIcon from '@/components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE, EMAIL_LOG_STATUSES_COLORS } from '@/common/constants';
import { formatDateTime } from '@/common/utils';

export default {
  components: { MdIcon, PageHeader, Pagination },
  extends: Page,

  name: 'EmailLogs',

  data() {
    return {
      title: 'Email Logs',
      EMAIL_LOG_STATUSES_COLORS,

      isLoading: false,

      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,

      totalLogs: 0,
      logs: [],

      searchTerm: '',
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Email Temaplates', to: { name: 'email-management-list' } },
        { text: 'Email Logs', active: true },
      ];
    },

    tableColumns() {
      return [
        { key: 'email_title', label: 'Title' },
        { key: 'status', label: 'Status' },
        { key: 'sent_to_email', label: 'Sent to' },
        { key: 'sent_from_email', label: 'Sent from' },
        { key: 'created_at', label: 'Sent On' },
      ];
    },
  },

  methods: {
    ...mapActions('emailConfig', ['getEmailLogs']),
    formatDateTime,

    async fetchLogs(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();
      let response;

      response = await this.getEmailLogs({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params,
      });

      this.logs = response.data.results;
      this.currentPage = pageNum;
      this.totalLogs = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchLogs(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchLogs();
    },

    onSearch() {
      this.debouncedSearchLogs(this);
    },

    debouncedSearchLogs: debounce((vm) => {
      vm.fetchLogs();
    }, 500),
  },

  async mounted() {
    this.fetchLogs();
  },
};
</script>
