<template>
  <div>
    <page-header
      :title="title"
      :container-class="containerClass"
      info-text="Manage pre-enrollment requests of students."
    />
    <div class="page-section">
      <div :class="containerClass">
        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input
                    class="w-lg-auto"
                    placeholder="Search Pre-enrollments"
                    v-model="searchTerm"
                    @input="onSearch"
                  />
                  <b-btn variant="flush" type="submit">
                    <md-icon>search</md-icon>
                  </b-btn>
                </form>
              </div>

              <!-- Filters and Add Btn -->
              <div class="col-lg d-flex flex-wrap justify-content-end">
                <!-- TODO: Add filters here. (if needed) -->
                <!-- <b-dropdown class="ml-lg-auto" :text="$t('All Topics')" variant="link text-black-70" right>
                  <b-dropdown-item active>All Topics</b-dropdown-item>
                  <b-dropdown-item>My Topics</b-dropdown-item>
                </b-dropdown>

                <b-dropdown class="mr-8pt" :text="$t('Newest')" variant="link text-black-70" right>
                  <b-dropdown-item active>Newest</b-dropdown-item>
                  <b-dropdown-item>Unanswered</b-dropdown-item>
                </b-dropdown> -->
                <!--
                <b-btn :to="{ name: 'add-student' }" exact variant="primary" v-text="$t('studentMsgs.addStudent')" /> -->
              </div>
            </div>
          </div>

          <!-- Students Table -->
          <b-table
            :fields="tableColumns"
            :items="students"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap"
            hover
            responsive
            no-local-sorting
            @row-clicked="onRowClicked"
            @sort-changed="onSortChange"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>

            <template #cell(menu)="data">
              <div class="text-center">
                <b-dropdown :boundary="'main'" no-flip variant="flush" toggle-class="text-muted" no-caret>
                  <template #button-content>
                    <i class="material-icons icon-24pt card-course__icon-favorite text-info">more_vert</i>
                    <span class="sr-only">Menu</span>
                  </template>

                  <b-dropdown-item
                    @click.prevent="resend(data.item.id)"
                    v-if="data.item.status === STD_PRE_ENROLL_STATUSES.APPROVED"
                  >
                    <md-icon class="text-info">replay</md-icon> Resend Email
                  </b-dropdown-item>

                  <b-dropdown-item
                    @click.prevent="copyUrl(data.item)"
                    v-if="data.item.status === STD_PRE_ENROLL_STATUSES.APPROVED"
                  >
                    <md-icon class="text-info">content_copy</md-icon> Copy Invite Url
                  </b-dropdown-item>

                  <b-dropdown-item
                    @click.prevent="updateStatus(data.index, 'disapproved')"
                    v-if="
                      [STD_PRE_ENROLL_STATUSES.APPROVED, STD_PRE_ENROLL_STATUSES.PENDING].includes(data.item.status)
                    "
                  >
                    <md-icon class="text-danger">block</md-icon> Disapprove Request
                  </b-dropdown-item>

                  <b-dropdown-item
                    @click.prevent="updateStatus(data.index, 'approved')"
                    v-if="
                      [STD_PRE_ENROLL_STATUSES.DISAPPROVED, STD_PRE_ENROLL_STATUSES.PENDING].includes(data.item.status)
                    "
                  >
                    <md-icon class="text-success">check</md-icon> Approve Request
                  </b-dropdown-item>

                  <b-dropdown-item :href="`mailto:${data.item.email}`">
                    <md-icon class="text-info">email</md-icon> Email Student
                  </b-dropdown-item>

                  <b-dropdown-item :href="`tel:${data.item.phone}`">
                    <md-icon class="text-info">phone</md-icon> Call Student
                  </b-dropdown-item>

                  <b-dropdown-item class="reg-table-item" @click.prevent="openAddCommentModal(data.item)">
                    <md-icon class="text-info">comment</md-icon>
                    {{ data.item.comment ? 'View Notes' : 'Add Notes' }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
            <template #cell(name)="data"
              ><strong>{{ data.item.first_name }} {{ data.item.last_name }}</strong>

              <span @click.stop v-if="data.item.comment">
                <md-icon
                  @click.native.prevent="openAddCommentModal(data.item)"
                  style="font-size: 18px"
                  class="ml-2 clickable-item comment-hover"
                  >comment</md-icon
                >
              </span></template
            >
            <template #cell(status)="data">
              <b-badge
                :class="`border border-${STD_PRE_ENROLL_STATUSES_COLORS[data.item.status]} text-${
                  STD_PRE_ENROLL_STATUSES_COLORS[data.item.status]
                }`"
                variant="none"
                >{{ $t(`preEnrollStatuses.${data.item.status}`) }}
              </b-badge>
            </template>
            <template #cell(created_at)="data"> {{ formatDateTime(data.item.created_at) }} </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalStudents"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="students-table"
            />
          </div>
        </div>
      </div>
    </div>
    <add-comment-modal
      :show-modal="showAddCommentModal"
      :reg="comment"
      is-preenroll-comment
      @close="hideAddCommentModal"
      @commentUpdated="onCommentUpdate"
    />
  </div>
</template>

<script>
import { debounce } from 'lodash';
import Page from '@/components/Page.vue';
import { mapActions, mapGetters } from 'vuex';
import AddCommentModal from './AddCommentModal.vue';
import { formatDateTime } from '../../../common/utils';
import MdIcon from '../../../components/Ui/MdIcon.vue';
import PageHeader from '@/components/Ui/PageHeader.vue';
import Pagination from '../../../components/Ui/Pagination.vue';
import { DEFAULT_PAGE_SIZE, STD_PRE_ENROLL_STATUSES_COLORS, STD_PRE_ENROLL_STATUSES } from '../../../common/constants';

export default {
  components: { MdIcon, PageHeader, Pagination, AddCommentModal },
  extends: Page,

  data() {
    return {
      title: 'Manage Pre-enrollments',
      STD_PRE_ENROLL_STATUSES_COLORS,
      STD_PRE_ENROLL_STATUSES,
      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalStudents: 0,
      students: [],
      isResending: false,
      searchTerm: '',
      ordering: 'status',
      showAddCommentModal: false,
      comment: null,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Manage Pre-enrollments', active: true },
      ];
    },

    tableColumns() {
      return [
        {
          key: 'menu',
          tdClass: 'text-left',
        },
        { key: 'name', label: this.$t('studentMsgs.name'), tdClass: 'clickable-item' },
        { key: 'status', label: 'Status', tdClass: 'clickable-item', sortable: true },

        { key: 'email', label: this.$t('authMsgs.email'), tdClass: 'clickable-item' },
        { key: 'phone', label: this.$t('studentMsgs.phone'), tdClass: 'clickable-item' },
        { key: 'created_at', label: 'Requested On', tdClass: 'clickable-item' },
      ];
    },
  },

  methods: {
    ...mapActions('preEnrollForm', ['getPreEnrolledStds', 'updatePreEnrollStatus', 'resendPreEnrollEmail']),
    formatDateTime,
    onRowClicked(data) {
      this.$router.push({
        name: 'schl-pre-enroll-student-profile',
        params: { id: data.id },
        query: { program: data.class_enrolled.program },
      });
    },
    async resend(id) {
      this.isResending = true;
      try {
        await this.resendPreEnrollEmail(id);
        this.fetchPreEnrolledStudents();

        this.makeToast({ variant: 'success', msg: 'Resent email.' });
      } catch ($e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isResending = false;
    },
    async copyUrl(data) {
      try {
        const urlLink = `${this.getLoggedInUser.linked_entity.subdomain.subdomain_url}/registration?pre_enrollment_id=${data.id}`;
        await navigator.clipboard.writeText(urlLink);
        this.isCopied = true;
        setTimeout(() => (this.isCopied = false), 2000);

        this.makeToast({ variant: 'success', msg: 'Invite link copied.' });
      } catch ($e) {
        this.makeToast({ variant: 'danger', msg: 'Unable to copy.' });
      }
    },
    async updateStatus(index, statusText) {
      this.isLoading = true;
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          `Please confirm that you want to mark the status as ${statusText}.`,
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'success',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (isConfirmed) {
          await this.updatePreEnrollStatus({ id: this.students[index].id, data: { status: statusText } });
          this.fetchPreEnrolledStudents();
          this.makeToast({ variant: 'success', msg: 'Status changed successfully!' });
        }
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },

    async fetchPreEnrolledStudents(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getPreEnrolledStds({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params,
      });
      this.students = response.data.results;
      this.currentPage = pageNum;
      this.totalStudents = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchPreEnrolledStudents(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchPreEnrolledStudents();
    },

    onSearch() {
      this.debouncedSearchPreEnrolledStudents(this);
    },

    debouncedSearchPreEnrolledStudents: debounce((vm) => {
      vm.fetchPreEnrolledStudents();
    }, 500),

    openAddCommentModal(item) {
      this.comment = item;
      this.showAddCommentModal = true;
    },
    hideAddCommentModal() {
      this.showAddCommentModal = false;
      this.comment = null;
    },
    onCommentUpdate(data) {
      const student = this.students.find((std) => std.id === data.id);
      if (student) student.comment = data.comment;
    },
  },

  async mounted() {
    this.fetchPreEnrolledStudents();
  },
};
</script>
