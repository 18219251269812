<template>
  <b-modal ref="addCommentModal" title="Notes" centered lazy @hide="hideModal" size="md" scrollable>
    <b-form v-if="canUpdate" class="col-md-12 px-0 pt-0">
      <b-form-group label-class="form-label">
        <b-form-textarea id="comment" placeholder="Enter your notes here." :rows="10" v-model="comment" />
      </b-form-group>
    </b-form>

    <p v-else v-html="formattedText"></p>

    <template #modal-footer>
      <div class="w-100">
        <b-button
          variant="primary"
          size="sm"
          class="float-right"
          @click.prevent="() => (canUpdate ? onSubmit() : (canUpdate = true))"
        >
          {{ canUpdate ? 'Save Notes' : 'Update Notes' }}
          <md-icon v-if="!canUpdate" class="ml-1">edit</md-icon>
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { get } from 'lodash';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'AddCommentModal',

  props: {
    showModal: { type: Boolean, default: false },
    showPreview: { type: Boolean, default: true },
    reg: { type: Object, default: null },
    isPreenrollComment: { type: Boolean, default: false }
  },

  data() {
    return {
      comment: '',
      isLoading: false,
      canUpdate: true
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),

    isFormLoading() {
      return this.isLoading;
    },

    formattedText() {
      return this.comment.replace(/\n/g, '<br>');
    }
  },
  methods: {
    ...mapActions('student', ['updateStdComment']),
    ...mapActions('preEnrollForm', ['updatePreenrollComment']),

    get,

    resetData() {
      this.comment = '';
    },

    async onSubmit() {
      this.isLoading = true;
      try {
        const data = {
          comment: this.comment
        };

        if (this.isPreenrollComment) {
          await this.updatePreenrollComment({ id: this.reg.id, data });
        } else {
          await this.updateStdComment({ reg_id: this.reg.id, ...data });
        }
        this.$emit('commentUpdated', { id: this.reg.id, comment: this.comment });
        this.makeToast({ variant: 'success', msg: 'Notes Saved Successfully!' });
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
      this.$refs.addCommentModal.hide();
    },

    async hideModal() {
      this.$emit('close');
      this.resetData();
    },

    hideModalManual() {
      this.$refs.addCommentModal.hide();
      this.hideModal();
    }
  },
  watch: {
    showModal(value) {
      if (value) {
        this.comment = this.reg.comment || '';
        this.canUpdate = !(this.comment && this.showPreview);
        this.$refs.addCommentModal.show();
      }
    }
  },
  mounted() {}
};
</script>

<style></style>
