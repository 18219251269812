<template>
  <div>
    <page-header
      :title="title"
      :container-class="containerClass"
      :breadcrumb="breadcrumb"
      class="mb-32pt"
      button-label="Email Logs"
      :button-path="{ name: 'manage-email-logs' }"
      button-variant="secondary"
      info-text="Manage configurable email content and view system generate emails."
    />
    <div class="page-section">
      <div :class="containerClass">
        <div class="card mb-0">
          <b-tabs
            nav-class="nav-tabs-card bg-white px-3 "
            content-class="mt-2"
            class="mt-2"
            id="classTab"
            @input="onTabsChanged"
            v-model="tabIndex"
            style="scroll-margin-top: 66px"
            lazy
          >
            <b-tab title="Configurable" class="tab-secondary">
              <email-tabs
                :emails="emails"
                :per-page="perPage"
                :total-emails="totalEmails"
                :is-loading="isLoading"
                :email-type="emailType"
                :search-term.sync="searchTerm"
                @onSort="onSortChange"
                @edit="onRowClicked"
                @pageChange="onPageChange"
                @search="onSearch"
                @fetch="fetchEmails"
              />
            </b-tab>
            <b-tab title="System Generated" class="tab-secondary">
              <email-tabs
                :emails="fetchSystemEmails"
                :per-page="perPage"
                :total-emails="totalEmails"
                :is-loading="isLoading"
                :email-type="emailType"
                :search-term.sync="searchTerm"
                @onSort="onSortChange"
                @edit="onSystemRowClicked"
                @pageChange="onPageChange"
                @search="onSearch"
              />
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import { debounce } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import Page from '@/components/Page.vue';
import { DEFAULT_PAGE_SIZE, EMAIL_TYPES, SYSTEM_EMAILS, SCHOOL_PAYMENT_PLANS } from '../../../common/constants';

import EmailTabs from './EmailTabs.vue';

export default {
  components: { PageHeader, EmailTabs },
  extends: Page,

  data() {
    return {
      title: 'Manage Branding',
      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalEmails: 0,
      emails: [],
      searchTerm: '',
      tabIndex: 0,
      emailType: EMAIL_TYPES.CONFIGURE,
      SCHOOL_PAYMENT_PLANS,
      SYSTEM_EMAILS,
    };
  },

  computed: {
    ...mapGetters('school', ['getSchoolPaymentPlan']),
    breadcrumb() {
      return [{ text: 'Email Templates', active: true }];
    },
    fetchSystemEmails() {
      return this.SYSTEM_EMAILS.filter((email) =>
        this.getSchoolPaymentPlan &&
        (this.getSchoolPaymentPlan.payment_plan === this.SCHOOL_PAYMENT_PLANS.BASIC_AND_CHAT ||
          this.getSchoolPaymentPlan.payment_plan === this.SCHOOL_PAYMENT_PLANS.ADVANCED_AND_CHAT)
          ? true
          : email.email_abbr !== 'close_chats'
      );
    },
  },

  methods: {
    ...mapActions('emailConfig', ['getAllEmails']),

    typeFilterApplied() {
      this.$router.replace({
        name: 'email-management-list',
        query: {
          type: this.tabIndex === 0 ? EMAIL_TYPES.CONFIGURE : EMAIL_TYPES.SYSTEM,
        },
      });
    },

    onTabsChanged() {
      if (this.tabIndex === 0) {
        this.emailType = EMAIL_TYPES.CONFIGURE;
      } else {
        this.emailType = EMAIL_TYPES.SYSTEM;
      }

      this.typeFilterApplied();
    },

    onRowClicked(data) {
      this.$router.push({
        name: 'edit-email',
        params: { id: data.id, type: this.emailType },
      });
    },

    onSystemRowClicked(data) {
      this.$router.push({
        name: 'view-system-email',
        params: { id: data.id, type: this.emailType },
      });
    },

    async fetchEmails(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getAllEmails({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),

        ...params,
      });
      this.emails = response.data.results;
      this.currentPage = pageNum;
      this.totalEmails = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchEmails(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchEmails();
    },

    onSearch() {
      this.debouncedSearchEmails(this);
    },

    debouncedSearchEmails: debounce((vm) => {
      vm.fetchEmails();
    }, 500),
  },

  async mounted() {
    this.tabIndex = this.$route.query.type === EMAIL_TYPES.SYSTEM ? 1 : 0;
    if (this.$route.query.type !== EMAIL_TYPES.SYSTEM) {
      this.fetchEmails();
    }
  },
};
</script>
