<template>
  <div :class="containerClass">
    <page-header
      title="Chatbot Details"
      :container-class="null"
      class="mb-16pt"
      :info-text="
        getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL
          ? `Enhance user interactions by customizing your chatbot configuration.`
          : ''
      "
    />

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <b-form-group label="Name" label-for="chatbot_name" label-class="form-label">
        <b-form-input id="chatbot_name" placeholder="Enter chatbot name" v-model="name" maxlength="50" />
      </b-form-group>

      <b-form-group class="row-align-items-center">
        <label class="form-label"> Image </label>
        <b-media class="align-items-center bg-white" vertical-align="center">
          <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
            <span v-if="isUploading.chatBotImage">...</span>
            <b-img :src="chatBotImageUrl" class="img-fluid" width="40" alt="Logo" v-else-if="chatBotImageUrl" />
            <i class="fas fa-university fa-lg" v-else></i>
          </fmv-avatar>

          <image-uploader
            @image="e => setImage(e, 'chatBotImage', 'chatBotImageUrl')"
            :isUploading="isUploading.chatBotImage"
            :isRequired="!chatBotImageUrl"
            placeholder="Select a chatbot image"
          />
        </b-media>
      </b-form-group>

      <b-form-group label-for="chatbot_welcome_message" label-class="form-label">
        <label>
          Welcome Message
          <i
            class="material-icons icon-16pt clickable-item text-muted"
            v-b-popover.hover.top="
              `Use {{program_name}}, {{school_name}} and {{chatbot_name}}  bookmarks for showing relevant school and program info.`
            "
            >info</i
          >
        </label>
        <b-form-textarea
          id="chatbot_welcome_message"
          :placeholder="
            `Welcome to {{school_name}} chat support. I am here to answer any questions you may have about our {{program_name}} Program. How can I assist you today?`
          "
          v-model="welcomeMsg"
          :rows="3"
        />
      </b-form-group>

      <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>{{ $t('update') }}</span>
      </b-btn>
    </b-form>
  </div>
</template>

<script>
import axios from 'axios';
import PageHeader from '@/components/Ui/PageHeader.vue';
import { mapActions, mapGetters } from 'vuex';
import { FmvAvatar } from 'fmv-avatar';
import ImageUploader from '@/components/ImageUploader.vue';

import { USER_ROLE_TYPES } from '@/common/constants';
import Page from '@/components/Page.vue';
export default {
  extends: Page,
  components: { FmvAvatar, PageHeader, ImageUploader },
  data() {
    return {
      title: 'Chatbot',
      name: '',
      welcomeMsg: '',
      chatBotImageUrl: null,
      isLoading: false,
      isUploading: { chatBotImage: null },
      selectedUploadFile: { chatBotImage: null },
      uploadPercent: { chatBotImage: null },
      uploadCancelTokenSource: { chatBotImage: null },
      USER_ROLE_TYPES
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    isFormLoading() {
      return this.isLoading;
    }
  },
  methods: {
    ...mapActions('myAI', ['getChatDetails', 'updateChatDetails']),
    ...mapActions('school', ['getSchoolImageUploadPresignedUrl']),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    setImage(file, chatBotImage, chatBotImageUrl) {
      this.selectedUploadFile.chatBotImage = file;
      this.uploadFile(chatBotImage, chatBotImageUrl);
    },
    async onSubmit() {
      this.isLoading = true;
      try {
        const data = {
          chatbot_name: this.name,
          chatbot_wlcm_msg: this.welcomeMsg,
          chatbot_image_url: this.chatBotImageUrl
        };

        await this.updateChatDetails({ data });
        this.makeToast({ variant: 'success', msg: 'Chatbot Details Updated' });
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }

      this.isLoading = false;
    },
    async uploadFile(fileType, profileUrlField = 'logo_url') {
      this.uploadCancelTokenSource[fileType] = axios.CancelToken.source();
      this.isUploading[fileType] = true;

      try {
        const urlResp = await this.getSchoolImageUploadPresignedUrl({
          file_name: this.selectedUploadFile[fileType].name,
          content_type: this.selectedUploadFile[fileType].type
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.selectedUploadFile[fileType],
          config: {
            onUploadProgress: function(progressEvent) {
              this.uploadPercent[fileType] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[fileType].token
          }
        });
        if (profileUrlField === 'chatBotImageUrl') {
          this.chatBotImageUrl = urlResp.upload_url.split('?')[0];
        }
      } catch (error) {
        this.selectedUploadFile[fileType] = null;
      }

      this.uploadCancelTokenSource[fileType] = null;
      this.uploadPercent[fileType] = 0;

      this.isUploading[fileType] = false;
    },
    async fetchChatDetails() {
      this.isLoading = true;
      try {
        const resp = (await this.getChatDetails()).data;

        this.name = resp.chatbot_name;
        this.welcomeMsg = resp.chatbot_wlcm_msg;
        this.chatBotImageUrl = resp.chatbot_image_url;
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }

      this.isLoading = false;
    }
  },
  async mounted() {
    this.fetchChatDetails();
  }
};
</script>

<style></style>
