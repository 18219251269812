<template>
  <b-modal
    ref="inquiryDetailModal"
    hide-footer
    title="Info and Contact Details"
    centered
    lazy
    @hide="hideModal"
    size="md"
  >
    <div class="row">
      <div class="col-lg-10">
        <div class="d-flex align-items-start">
          <span><b>Name:</b> {{ contactInfo.first_name }} {{ contactInfo.last_name }} </span>
        </div>

        <div class="d-flex align-items-between">
          <span><b>Email:</b> {{ contactInfo.email }} </span>
        </div>

        <div class="d-flex align-items-between">
          <span><b>Phone:</b> {{ contactInfo.phone }} </span>
        </div>

        <div class="d-flex align-items-between">
          <span><b>Permission To Text:</b> {{ contactInfo.is_text_allowed ? 'Yes' : 'No' }} </span>
        </div>

        <div class="d-flex align-items-between">
          <span><b>Program:</b> {{ get(contactInfo, 'program.title', '') }} </span>
        </div>

        <div class="d-flex align-items-between">
          <span><b>Type:</b> {{ $t(`programInquiryTypes.${get(contactInfo, 'types', '')}`) }} </span>
        </div>

        <div v-if="get(contactInfo, 'types') === 'scholarship' && get(contactInfo, 'extra.length')">
          <div><b> Sponsorships: </b></div>
          <ul>
            <li class="mb-1" v-for="scholarship in get(contactInfo, 'extra')" :key="scholarship.id">
              <a
                class="text-primary"
                :href="`${subdomain || 'app.jointransition.com'}/sponsorships/${scholarship.id}`"
                target="_blank"
              >
                {{ scholarship.title }}
              </a>
            </li>
          </ul>
        </div>

        <div class="d-flex align-items-between" v-if="get(contactInfo, 'created_at')">
          <span><b>Contacted At:</b> {{ formatDateTime(contactInfo.created_at) }} </span>
        </div>
      </div>
      <div class="col-lg-2">
        <div class="d-flex justify-content-end">
          <b-dropdown text="Contact" variant="primary" class="ml-2">
            <b-dropdown-item :href="`mailto:${contactInfo.email}`"
              ><i class="material-icons mr-1">email</i>Email</b-dropdown-item
            >
            <b-dropdown-item :href="`tel:${contactInfo.phone}`"
              ><i class="material-icons mr-1">phone</i>Call</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </div>
      <div class="col-12">
        <div><b>Message:</b></div>
        <div v-safe-html="message" class="bg-light w-100 px-2 py-1 rounded" />
      </div>
    </div>
  </b-modal>
</template>

<script>
import { get } from 'lodash';
import { USER_ROLE_TYPES } from '../../../common/constants';
import { formatDateTime } from '../../../common/utils';
export default {
  name: 'InquiryDetailModal',

  props: {
    contactInfo: Object,
    showModal: { type: Boolean, default: false },
    subdomain: { type: String, default: '' },
  },

  data() {
    return {
      USER_ROLE_TYPES,
      message: '',
    };
  },

  computed: {},

  watch: {
    showModal(value) {
      if (value) {
        this.message = '';
        let htmlMessage = this.contactInfo.comments;
        const htmlEntities = [
          { regex: '&', entity: '&amp;' },
          { regex: '>', entity: '&gt;' },
          { regex: '<', entity: '&lt;' },
        ];
        htmlEntities.forEach((ent) => {
          const reg = new RegExp(ent.regex, 'g');
          htmlMessage = htmlMessage.replace(reg, ent.entity);
        });
        this.message = htmlMessage.replace(/(?:\r\n|\r|\n)/g, '<br>');

        this.$refs.inquiryDetailModal.show();
      }
    },
  },
  methods: {
    get,
    formatDateTime,

    hideModal() {
      this.$emit('close');
    },

    hideModalManual() {
      this.$refs.inquiryDetailModal.hide();
      this.hideModal();
    },
  },

  async mounted() {},
};
</script>
