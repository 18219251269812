<template>
  <div>
    <page-header :title="title" :container-class="containerClass" />
    <div class="page-section border-bottom-2">
      <div :class="containerClass">
        <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
          <div class="card border-left-0">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3">
                    <h5 class="card-title fw600">Filter by Date(s)</h5>
                  </div>

                  <div>
                    <date-picker
                      v-model="filters.dateRange"
                      format="MMMM DD, YYYY"
                      valueType="YYYY-MM-DD"
                      style="width: 100%"
                      id="date"
                      lang="en"
                      placeholder="Filter by Start Date - End Date Range"
                      range
                      class="w-100 mb-3"
                      :input-attr="{ required: 'true' }"
                    ></date-picker>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                    <h5 class="card-title fw600">Filter by Program</h5>
                  </div>
                  <div>
                    <v-select
                      id="programs"
                      class="form-control v-select-custom"
                      label="title"
                      v-model="filters.program"
                      :reduce="(item) => item.id"
                      placeholder="All Programs"
                      :options="allPrograms"
                    >
                      <template #search="{ attributes, events }">
                        <input class="vs__search" :required="!filters.program" v-bind="attributes" v-on="events" />
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>

              <page-separator
                title="Background Related Information
          "
              />

              <div class="row">
                <div
                  v-for="(item, index) in background_info"
                  :key="index"
                  class="col-lg-6 col-md-6 col-sm-12 clickable-item"
                >
                  <div class="mt-4 mb-0 p-3 my-auto d-flex justify-content-start">
                    <b-form-checkbox v-model="apiData[item]">
                      <div class="d-flex">
                        <span style="font-size: 16px">{{ $t(`enrollmentAnalytics.${item}`) }}</span>
                      </div>
                    </b-form-checkbox>
                  </div>
                </div>
              </div>

              <page-separator
                title="Payment Related Information
          "
              />

              <div class="row">
                <div
                  v-for="(item, index) in payment_info"
                  :key="index"
                  class="col-lg-6 col-md-6 col-sm-12 clickable-item"
                >
                  <div class="mt-4 mb-0 p-3 my-auto d-flex justify-content-start">
                    <b-form-checkbox v-model="apiData[item]">
                      <div class="d-flex">
                        <span style="font-size: 16px">{{ $t(`enrollmentAnalytics.${item}`) }}</span>
                      </div>
                    </b-form-checkbox>
                  </div>
                </div>
              </div>

              <page-separator
                title="Career Related Information
          "
              />

              <div class="row">
                <div
                  v-for="(item, index) in career_info"
                  :key="index"
                  class="col-lg-6 col-md-6 col-sm-12 clickable-item"
                >
                  <div class="mt-4 mb-0 p-3 my-auto d-flex justify-content-start">
                    <b-form-checkbox v-model="apiData[item]">
                      <div class="d-flex">
                        <span style="font-size: 16px">{{ $t(`enrollmentAnalytics.${item}`) }}</span>
                      </div>
                    </b-form-checkbox>
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-end">
                <b-btn variant="primary" style="width: 200px" type="submit" class="btn-normal mb-2">
                  <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
                  <span v-else>Export Analytics<span class="material-icons icon-16pt ml-1">file_download</span></span>
                </b-btn>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
    <div>
      <!-- Your modal trigger button goes here -->
    </div>
    <b-modal ref="myModal" title="Downloading Report" centered hide-footer @hide="hideModal">
      <span v-if="!fileUrl">
        <i
          style="font-size: 3rem"
          class="d-flex justify-content-center fas fa-circle-notch fa-spin text-primary mb-2"
        ></i>
        <span>Your file is being downloaded, we will notify you once it’s ready.</span>
        <p class="text-70 text-center">
          Note: You can safely move to another page. You will receive a notification shortly.
        </p>
      </span>
      <span v-else>
        <i
          style="font-size: 3rem"
          class="d-flex justify-content-center material-icons text-primary mb-2 clickable-item"
          @click.prevent="downloadGivenFile"
          >file_download</i
        >
        <span>Your file is ready to be downloaded. Click to download.</span>
      </span>
    </b-modal>
  </div>
</template>

<script>
import PageHeader from '../../../components/Ui/PageHeader.vue';
import { mapActions, mapGetters } from 'vuex';
import { get } from 'lodash';
import DatePicker from 'vue2-datepicker';
import moment from 'moment';
import vSelect from 'vue-select';
import Page from '@/components/Page.vue';
import { formatToAPIDate } from '../../../common/utils';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
export default {
  components: { PageHeader, DatePicker, vSelect, PageSeparator },
  extends: Page,

  data() {
    return {
      title: 'Enrollment Analytics',
      background_info: ['gender', 'age', 'language', 'ethnicity'],
      payment_info: ['tuition_fee_status', 'payment_option'],
      career_info: ['work_type', 'facility_interest'],
      areProgramsLoading: false,
      isLoading: false,
      connection: null,
      apiData: {
        gender: true,
        age: true,
        language: true,
        ethnicity: true,
        tuition_fee_status: true,
        payment_option: true,
        work_type: true,
        facility_interest: true,
      },
      filters: {
        dateRange: [],
        program: null,
      },
      allPrograms: [],
      bis: [],
      fileUrl: null,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser', 'getAuthToken']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Dashboard', active: true },
      ];
    },
  },

  methods: {
    ...mapActions('program', ['getAllPrograms']),
    ...mapActions('student', ['createAnalysisReport']),
    ...mapActions('fileDownload', ['downloadFile']),
    get,
    resetFile() {
      this.fileUrl = null;
    },
    openModal() {
      this.$refs.myModal.show();
    },
    hideModal() {
      this.resetFile();
      this.$refs.myModal.hide();
      this.closeConnection();
    },
    async downloadGivenFile({ removeTimestamp = true }) {
      const h = this.$createElement;
      this.makeToast({
        variant: 'primary',
        msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'Downloading File']),
        toaster: 'b-toaster-bottom-left',
      });
      try {
        await this.downloadFile({ fileUrl: this.fileUrl, removeTimestamp });

        this.makeToast({
          variant: 'success',
          msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'File Downloaded']),
          toaster: 'b-toaster-bottom-left',
        });
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.hideModal();
    },
    hookUrlWS(timestamp) {
      this.connection = new WebSocket(
        `${process.env.VUE_APP_BACKEND_WS_URL}/ws/analytics/${this.getLoggedInUser.id}/${timestamp}/?token=${this.getAuthToken}`
      );

      this.connection.onmessage = (event) => {
        const data = JSON.parse(event.data).message;

        if (data.action === 'Create') {
          this.fileUrl = data.file_url;
        }
      };
      this.connection.onerror = () => {};
    },
    closeConnection() {
      if (this.connection) this.connection.close();
    },
    async fetchPrograms() {
      this.areProgramsLoading = true;

      const response = await this.getAllPrograms();
      this.allPrograms = response.data;

      this.areProgramsLoading = false;
    },

    async onSubmit() {
      this.isLoading = true;
      try {
        const data = {
          ...this.apiData,
          program: this.filters.program,
          date_from: this.filters.dateRange[0],
          date_to: this.filters.dateRange[1],
        };
        const res = await this.createAnalysisReport(data);
        this.hookUrlWS(res.timestamp);
        this.openModal();
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },
  },

  async mounted() {
    this.fetchPrograms();
    this.filters.dateRange = [formatToAPIDate(moment().startOf('month')), formatToAPIDate(moment().endOf('month'))];
  },
};
</script>
