<template>
  <div>
    <page-header
      :title="title"
      :container-class="containerClass"
      :breadcrumb="breadcrumb"
      info-text="Manage enrollemnt questions to associate them with enrollment form of each program."
    />
    <div class="page-section">
      <div :class="containerClass">
        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input
                    class="w-lg-auto"
                    placeholder="Search Questions"
                    v-model="searchTerm"
                    @input="onSearch"
                  />

                  <b-btn variant="flush" type="submit">
                    <md-icon v-text="'search'" />
                  </b-btn>
                </form>
              </div>

              <!-- Filters and Add Btn -->
              <div class="col-lg d-flex flex-wrap justify-content-end">
                <div class="col-lg-6 mx-0 px-0"></div>
                <!-- TODO: Add filters here. (if needed) -->
                <!-- <b-dropdown class="ml-lg-auto" :text="$t('All Topics')" variant="link text-black-70" right>
                  <b-dropdown-item active>All Topics</b-dropdown-item>
                  <b-dropdown-item>My Topics</b-dropdown-item>
                </b-dropdown>

                <b-dropdown class="mr-8pt" :text="$t('Newest')" variant="link text-black-70" right>
                  <b-dropdown-item active>Newest</b-dropdown-item>
                  <b-dropdown-item>Unanswered</b-dropdown-item>
                </b-dropdown> -->

                <b-btn class="ml-2" :to="{ name: 'add-question' }" exact variant="primary" v-text="'Add Question'" />
              </div>
            </div>
          </div>

          <!-- Schools Table -->
          <b-table
            :fields="tableColumns"
            :items="allQuestions"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap"
            hover
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
            @row-clicked="editInstructor"
          >
            <template #cell(question)="data"> {{ data.item.title }}</template>

            <template #cell(type)="data"> {{ $t(`quesTypeOptions.${data.item.type}`) }}</template>

            <template #head(actions)="">
              <span></span>
            </template>
            <template #cell(actions)="data">
              <router-link
                :to="{
                  name: 'edit-question',
                  params: { id: data.item.id },
                }"
                class="svg-icon mr-2"
                v-b-popover.hover.right
                :title="'Edit'"
              >
                <i class="material-icons">edit</i>

                <!-- <i class="far fa-edit text-info" /> -->
              </router-link>
              <a
                href="#"
                @click.prevent="removeQuestion(data.item.id)"
                v-b-popover.hover.right
                :title="'Remove Question'"
              >
                <i class="material-icons text-danger">delete</i>
              </a>
            </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalQuestions"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="schools-table"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import { debounce } from 'lodash';
import { mapActions } from 'vuex';

import Page from '@/components/Page.vue';
import Pagination from '@/components/Ui/Pagination.vue';
import MdIcon from '@/components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE } from '@/common/constants';

export default {
  components: { MdIcon, PageHeader, Pagination },
  extends: Page,

  data() {
    return {
      title: 'Manage Branding',

      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalQuestions: 0,
      searchTerm: '',
      allQuestions: [],
    };
  },

  computed: {
    breadcrumb() {
      return [{ text: 'Enrollment Questions', active: true }];
    },

    tableColumns() {
      return [
        { key: 'title', label: 'Question', tdClass: 'clickable-item' },
        { key: 'type', label: 'Type', tdClass: 'clickable-item' },

        {
          key: 'actions',
          tdClass: 'text-right',
          thStyle: { minWidth: '100px' },
        },
      ];
    },
  },

  methods: {
    ...mapActions('registrationForm', ['getCustomQuestions', 'deleteCustomQuestion']),

    async fetchQuestions(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getCustomQuestions({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params,
      });
      this.allQuestions = response.data.results;
      this.currentPage = pageNum;
      this.totalQuestions = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchQuestions(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchQuestions();
    },

    editInstructor(data) {
      this.$router.push({ name: 'edit-question', params: { id: data.id } });
    },

    onSearch() {
      this.debouncedSearchQuestions(this);
    },

    debouncedSearchQuestions: debounce((vm) => {
      vm.fetchQuestions();
    }, 500),

    async removeQuestion(id) {
      this.isLoading = true;
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          `Please confirm that you want to remove the selected question. The question will no longer appear on the registration form and all existing responses will be removed.`,
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (isConfirmed) {
          await this.deleteCustomQuestion(id);
          this.fetchQuestions();
          this.makeToast({ variant: 'success', msg: 'Question removed successfully!' });
        } else {
          this.isLoading = false;
        }
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },
  },

  async mounted() {
    this.fetchQuestions();
  },
};
</script>
