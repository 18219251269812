<template>
  <div>
    <!-- emails Table -->
    <b-table
      :fields="tableColumns"
      :items="emails"
      :busy="isLoading"
      head-variant="light"
      class="table-nowrap"
      hover
      responsive
      no-local-sorting
      @row-clicked="editEmail"
      @sort-changed="onSortChanged"
    >
      <template #cell(title)="data"> {{ data.item.title }}</template>

      <template #cell(enabled)="data">
        <b-badge
          :class="'border border-' + (data.item.enabled ? `success badge-success` : `danger badge-danger`)"
          variant="none"
        >
          {{ data.item.enabled ? 'Enabled' : 'Disabled' }}
        </b-badge>
      </template>
      <template #cell(periodic_task)="data">
        <span v-if="data.item.periodic_task">{{
          moment(`${data.item.periodic_task.crontab.hour}.${data.item.periodic_task.crontab.minute}`, ['HH.mm']).format(
            'hh:mm a'
          )
        }}</span>
      </template>
      <template #cell(type)="data"> {{ $t(`emailTypes.${data.item.type}`) }}</template>
    </b-table>

    <!-- Footer Pagination -->
    <div class="card-footer">
      <pagination
        v-model="currPage"
        :total-rows="totalEmails"
        :per-page="perPage"
        @change="onPageChange"
        aria-controls="email-table"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Page from '@/components/Page.vue';
import Pagination from '@/components/Ui/Pagination.vue';
import { formatDateTime } from '@/common/utils';
import { EMAIL_TYPES } from '@/common/constants';
import moment from 'moment';
export default {
  components: { Pagination },
  extends: Page,
  props: {
    emails: { type: Array, default: () => [] },
    totalEmails: { type: Number, default: 0 },
    emailType: { type: String, default: '' },
    isLoading: { type: Boolean, default: false },
    perPage: { type: Number, default: 0 },
    searchTerm: { type: String, default: '' },
  },

  name: 'EmailTab',

  data() {
    return {
      currPage: 1,
      windowWidth: window.innerWidth,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Email Temaplates', to: { name: 'manage-emails' } },
        { text: 'Email Logs', active: true },
      ];
    },

    tableColumns() {
      return [
        { key: 'title', label: 'Title', tdClass: 'clickable-item' },
        ...(this.emailType === EMAIL_TYPES.CONFIGURE
          ? [{ key: 'enabled', label: this.$t('generalMsgs.status'), tdClass: 'clickable-item' }]
          : [{ key: 'time', label: 'Time' }]),

        { key: 'type', label: 'Type', tdClass: 'clickable-item' },
      ];
    },
  },

  methods: {
    moment,
    formatDateTime,
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    onPageChange(val) {
      this.$emit('pageChange', val);
    },
    onSortChanged(context) {
      this.$emit('onSort', context);
    },
    editEmail(row) {
      this.$emit('edit', row);
    },
    onSearch(val) {
      this.$emit('update:searchTerm', val);
      this.$emit('search');
    },
  },

  async mounted() {},
};
</script>
