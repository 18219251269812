<template>
  <div :class="containerClass">
    <page-header
      title="Create Marketing Post"
      :container-class="null"
      class="mb-16pt"
      :info-text="
        getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL
          ? `Fine-tune marketing post templates for effective communication with your audience.`
          : ''
      "
    />
    <div style="font-size: 16px">
      Simplify marketing content creation for your offered programs using our AI-driven platform.Choose a template below
      and get started right away.
    </div>
    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <page-separator>
        <b-link v-b-toggle="'section-1'" class="accordion__toggle p-0">
          <span class="flex">Choose a Template</span>
          <md-icon class="accordion__toggle-icon">
            {{ stepOneVisible ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
          </md-icon>
        </b-link>
      </page-separator>

      <b-collapse
        id="section-1"
        accordion="section-1"
        :visible="stepOneVisible"
        style="transition: 0.5s"
        class="accordion__menu"
        @hide="stepOneVisible = false"
        @show="stepOneVisible = true"
      >
        <div>
          <div class="ml-sm-auto mb-3 justify-content-end" :style="isMobScreen ? '' : 'width: 250px'">
            <div class="custom-select-icon">
              <b-form-select
                class="custom-select-icon__select"
                style="min-width: 185px"
                v-model="category"
                :options="categoryOptions"
                @input="fetchTemplates()"
              ></b-form-select>
              <span class="material-icons custom-select-icon__icon">sort</span>
            </div>
          </div>
        </div>

        <div class="row" v-if="isTemplatesLoading">
          <div class="col-md-4 col-6" v-for="i in [1, 2, 3, 4, 5, 6]" :key="i">
            <b-card>
              <b-skeleton class="mb-2" width="25px" height="25px"></b-skeleton>
              <b-skeleton width="25%" class="mb-2"></b-skeleton>
              <b-skeleton animation="wave" width="85%"></b-skeleton>
              <b-skeleton animation="wave" width="75%"></b-skeleton>
              <b-skeleton animation="wave" width="80%"></b-skeleton>
            </b-card>
          </div>
        </div>

        <div class="row" v-else>
          <div class="col-md-4 col-6" v-for="template in allTemplates" :key="template.id">
            <b-card
              class="border-hov"
              :class="template.id === selectedTemplate ? 'border-primary' : ''"
              style="cursor: pointer; height: 90%"
              :id="template.id"
              @click.prevent="openCanvas(template)"
            >
              <div>
                <img class="mb-2" :src="template.image" v-if="template.image" width="25" height="25" />
                <md-icon v-else style="font-size: 25px" class="mb-2 text-primary">descriptionoutlined</md-icon>
              </div>
              <div class="mb-2">
                <strong> {{ template.title }} </strong>
              </div>
              <b-card-text>{{ template.description }}</b-card-text>
            </b-card>
          </div>
        </div>
      </b-collapse>
      <div v-if="imageGeneration">
        <page-separator>
          <b-link v-b-toggle="'section-3'" class="accordion__toggle p-0">
            <span class="flex">Choose a Canvas</span>
            <md-icon class="accordion__toggle-icon">
              {{ stepThreeVisible ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
            </md-icon>
          </b-link>
        </page-separator>

        <b-collapse
          id="section-3"
          accordion="section-3"
          :visible="stepThreeVisible"
          style="transition: 0.5s"
          class="accordion__menu"
          @hide="stepThreeVisible = false"
          @show="stepThreeVisible = true"
        >
          <div class="row">
            <div class="col-md-3 col-6">
              <div
                class="card clickable-item border-hov d-flex justify-content-center align-items-center"
                :class="selectedCanvas === null ? 'border-primary' : ''"
                @click.prevent="openPrograms()"
                style="width: 100%; height: 120px"
              >
                <i class="fas fa-ban mb-1" style="font-size: 50px; color: gray"></i>

                <b> Not Required </b>
              </div>
            </div>
            <div class="col-md-3 col-6" v-for="canvas in canvases" :key="canvas.id">
              <div
                class="card clickable-item border-hov"
                :class="canvas.id === selectedCanvas ? 'border-primary' : ''"
                @click.prevent="openPrograms(canvas.id)"
              >
                <img style="border-radius: 5px" :src="canvas.sample_image" alt="" width="100%" height="120" />
              </div>
            </div>
          </div>
        </b-collapse>
      </div>

      <page-separator>
        <b-link v-b-toggle="'section-2'" class="accordion__toggle p-0">
          <span class="flex">Select Program</span>
          <md-icon class="accordion__toggle-icon">
            {{ stepTwoVisible ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
          </md-icon>
        </b-link>
      </page-separator>

      <b-collapse
        id="section-2"
        accordion="section-2"
        :visible="stepTwoVisible"
        style="transition: 0.5s"
        class="accordion__menu"
        @hide="stepTwoVisible = false"
        @show="stepTwoVisible = true"
      >
        <b-form-group label="Offered Programs" label-for="program" label-class="form-label">
          <v-select
            id="program"
            class="form-control v-select-custom"
            :class="!$v.program.required && $v.program.$dirty ? 'form-control is-invalid' : ''"
            label="title"
            v-model="program"
            :reduce="(program) => program.id"
            placeholder="Select Program"
            :options="allPrograms"
            :loading="areProgramsLoading"
            :clearable="false"
            v-b-popover.hover.right
            :title="!selectedTemplate ? 'Please choose a template first' : ''"
            :disabled="!selectedTemplate"
          >
            <template #search="{ attributes, events }">
              <input class="vs__search" v-bind="attributes" v-on="events" />
            </template>

            <template slot="option" slot-scope="option">
              <div class="d-flex align-items-center">
                <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
                  <b-img :src="option.image" class="img-fluid" width="20" alt="Logo" v-if="option.image" />
                  <md-icon v-else>local_library</md-icon>
                </fmv-avatar>
                <span>{{ option.title }}</span>
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              {{ option.title }}
            </template>
          </v-select>
          <b-form-invalid-feedback :state="!$v.program.required && $v.program.$dirty ? false : null"
            >This field is required.</b-form-invalid-feedback
          >
        </b-form-group>
      </b-collapse>
      <div class="text-center">
        <b-btn
          variant="primary"
          style="width: 100%"
          :disabled="isLoading || !selectedTemplate || !program"
          type="submit"
          class="btn-normal"
        >
          <!-- <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i> -->
          <span>{{ isRegenerating ? 'Regenerate Content' : 'Create Content' }}</span>
        </b-btn>
      </div>
    </b-form>

    <div v-if="isBothLoading">
      <div class="preloader">
        <div class="load-1"></div>
        <div class="load-2"></div>
        <div class="load-3"></div>
        <div class="load-4"></div>
        <div class="load-5"></div>
      </div>

      <div class="text-center">
        <i style="font-size: 17px"> Generating content... </i>
      </div>
    </div>

    <div v-else>
      <b-card v-if="isContentLoading">
        <b-skeleton animation="wave" width="50%"></b-skeleton>
        <b-skeleton animation="wave" width="60%"></b-skeleton>
        <b-skeleton animation="wave" width="70%"></b-skeleton>
        <b-skeleton animation="wave" width="85%"></b-skeleton>
      </b-card>

      <div v-else-if="content">
        <b-card>
          <span v-html="formatText(content)"></span>
        </b-card>
        <div class="d-flex align-items-center justify-content-end">
          <div class="text-center">
            <b-card
              :class="{ 'text-success': isContentCopied }"
              @click.prevent="copyPass()"
              class="clickable-item hover-item mr-2"
              style="min-width: 110px"
            >
              <i class="far fa-copy mx-1"></i> {{ isContentCopied ? 'Copied!' : 'Copy Text' }}
            </b-card>
          </div>
          <div class="text-center">
            <b-card @click.prevent="generateAIText()" class="clickable-item hover-item" style="min-width: 110px">
              <i class="fas fa-undo mx-1"></i> Regenerate
            </b-card>
          </div>
        </div>
      </div>
      <div v-if="isImageLoading">
        <div class="card">
          <b-skeleton-img></b-skeleton-img>
        </div>
      </div>
      <div v-else-if="generatedImage">
        <div class="card">
          <img
            :id="generatedImage.split('/').slice(-1)[0]"
            style="border-radius: 6px"
            :src="generatedImage"
            alt=""
            width="100%"
            height="auto"
          />
        </div>

        <div class="d-flex align-items-center justify-content-end">
          <div class="text-center">
            <b-card
              @click.prevent="downloadImage()"
              :class="{ 'text-success': isImageDownloaded }"
              class="clickable-item hover-item mr-2"
              style="min-width: 110px"
            >
              <i class="fas fa-download mx-1"></i>
              {{ isImageDownloaded ? 'Downloaded!' : 'Download Image' }}
            </b-card>
          </div>

          <div class="text-center">
            <b-card @click.prevent="generateAIImage()" class="clickable-item hover-item" style="min-width: 110px">
              <i class="fas fa-undo mx-1"></i> Regenerate
            </b-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { trim } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import Page from '@/components/Page.vue';
import vSelect from 'vue-select';
import { FmvAvatar } from 'fmv-avatar';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import PageHeader from '@/components/Ui/PageHeader.vue';
import { USER_ROLE_TYPES } from '@/common/constants';
export default {
  extends: Page,
  components: { vSelect, FmvAvatar, PageSeparator, PageHeader },
  data() {
    return {
      title: 'Create Marketing Post',
      stepOneVisible: true,
      stepTwoVisible: false,
      stepThreeVisible: false,
      selectedTemplate: null,
      program: null,
      allPrograms: [],
      areProgramsLoading: false,
      isLoading: false,
      allTemplates: [],
      isTemplatesLoading: false,
      content: '',
      isRegenerating: false,
      windowWidth: window.innerWidth,
      isContentCopied: false,
      category: null,
      categoryOptions: [
        { value: null, text: 'All Templates' },
        { value: 'social_media', text: 'Social Media' },
        { value: 'blog', text: 'Blog' },
        { value: 'email_marketing', text: 'Email Marketing' },
      ],
      isHover: false,
      hoverId: null,
      selectedCanvas: null,
      isCanvasHover: false,
      canvasHoverId: null,
      isImageDownloaded: false,
      imageGeneration: false,
      canvases: [],
      generatedImage: '',
      isImageLoading: false,
      isContentLoading: false,
      isCanvas: false,
      isCanvasesLoaded: false,
      USER_ROLE_TYPES,
    };
  },
  validations() {
    return {
      program: { required },
    };
  },
  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    isMobScreen() {
      return this.windowWidth <= 576;
    },
    isBothLoading() {
      return (this.isContentLoading && this.isImageLoading) || this.isLoading;
    },
  },
  methods: {
    ...mapActions('program', ['getAllPrograms']),
    ...mapActions('myAI', ['generateText', 'getAllTemplates', 'generateImage']),
    ...mapActions('fileDownload', ['downloadFile']),

    openCanvas(template) {
      this.selectedTemplate = template.id;
      this.imageGeneration = template.image_gen_enable;

      if (!this.imageGeneration) {
        this.openPrograms(template.id);
      } else {
        this.canvases = template.template_canvas;
        this.stepOneVisible = false;
        this.stepThreeVisible = true;
      }
    },
    openPrograms(id = null) {
      if (id) {
        this.selectedCanvas = id;
        this.isCanvas = true;
      } else {
        this.selectedCanvas = null;
        this.isCanvas = false;
      }
      this.stepOneVisible = false;
      this.stepThreeVisible = false;
      this.stepTwoVisible = true;
    },

    async downloadImage() {
      try {
        await this.downloadFile({
          fileUrl: this.generatedImage,
        });
        this.isImageDownloaded = true;
        setTimeout(() => (this.isImageDownloaded = false), 2000);
      } catch (error) {
        //
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },

    async fetchTemplates() {
      this.isTemplatesLoading = true;
      const response = await this.getAllTemplates({
        ...(this.category && { category: this.category }),
      });
      this.allTemplates = response.data;

      this.isTemplatesLoading = false;
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    formatText(string) {
      const result = trim(string, '\n');
      return result.replace(/\n/g, '<br />');
    },

    async generateAIText() {
      try {
        this.isContentLoading = true;
        this.isLoading = true;
        const res = await this.generateText({
          id: this.selectedTemplate,
          data: { program: this.program },
        });

        this.content = res.data.data.content;

        this.isContentLoading = false;
        this.isLoading = false;
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },

    async generateAIImage() {
      if (this.imageGeneration && this.isCanvas) {
        this.isImageLoading = true;
        try {
          const res = await this.generateImage({
            id: this.selectedCanvas,
            data: { program: this.program },
          });

          if (res) {
            this.generatedImage = res.data.data.image_url;
          }
        } catch (error) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
        this.isImageLoading = false;
      }
    },

    async onSubmit() {
      this.content = '';
      this.generatedImage = '';
      if (!this.selectedTemplate) {
        this.stepOneVisible = true;
        this.makeToast({ variant: 'danger', msg: 'Please Choose Template.' });
        return;
      }
      if (!this.program) {
        this.stepTwoVisible = true;
        this.makeToast({ variant: 'danger', msg: 'Please Choose Program.' });
        return;
      }

      this.stepOneVisible = false;
      this.stepTwoVisible = false;
      this.stepThreeVisible = false;
      this.isLoading = true;

      try {
        await Promise.all([this.generateAIText(), this.generateAIImage()]);

        this.isRegenerating = true;
      } catch (e) {
        //
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },
    async fetchPrograms() {
      this.areProgramsLoading = true;

      const response = await this.getAllPrograms({});
      this.allPrograms = response.data;

      this.areProgramsLoading = false;
    },
    async reset() {
      const isConfirmed = await this.$bvModal.msgBoxConfirm(
        `Please confirm that you want to reset the generated content of the selected template.`,
        {
          title: 'Are you sure?',
          size: 'md',
          buttonSize: 'sm',
          okVariant: this.content ? 'danger' : 'success',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        }
      );
      if (isConfirmed) {
        this.isRegenerating = false;
        this.selectedTemplate = null;
        this.program = null;
        this.category = null;
        this.content = '';
        this.stepOneVisible = true;
        this.stepTwoVisible = false;
        this.stepThreeVisible = false;
      }
    },

    async copyPass() {
      if (this.isContentCopied) {
        return;
      }

      try {
        await navigator.clipboard.writeText(this.content);
        this.isContentCopied = true;
        setTimeout(() => (this.isContentCopied = false), 2000);
      } catch ($e) {
        this.makeToast({ variant: 'danger', msg: 'Unable to copy.' });
      }
    },
  },
  async mounted() {
    window.addEventListener('resize', this.handleResize);
    this.fetchTemplates();
    this.fetchPrograms();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
.hover-item:hover {
  background-color: rgb(240, 237, 237);
}
</style>
