<template>
  <div>
    <page-header :title="title" :container-class="containerClass" info-text="List of all registered students." />
    <div class="page-section">
      <div :class="containerClass">
        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input
                    class="w-lg-auto"
                    :placeholder="$t('studentMsgs.searchStudents')"
                    v-model="searchTerm"
                    @input="onSearch"
                  />
                  <b-btn variant="flush" type="submit">
                    <md-icon v-text="'search'" />
                  </b-btn>
                </form>
              </div>

              <!-- Filters and Add Btn -->
              <div class="col-lg d-flex flex-wrap justify-content-end">
                <!-- TODO: Add filters here. (if needed) -->
                <!-- <b-dropdown class="ml-lg-auto" :text="$t('All Topics')" variant="link text-black-70" right>
                  <b-dropdown-item active>All Topics</b-dropdown-item>
                  <b-dropdown-item>My Topics</b-dropdown-item>
                </b-dropdown>

                <b-dropdown class="mr-8pt" :text="$t('Newest')" variant="link text-black-70" right>
                  <b-dropdown-item active>Newest</b-dropdown-item>
                  <b-dropdown-item>Unanswered</b-dropdown-item>
                </b-dropdown> -->
                <!--
                <b-btn :to="{ name: 'add-student' }" exact variant="primary" v-text="$t('studentMsgs.addStudent')" /> -->
              </div>
            </div>
          </div>

          <!-- Students Table -->
          <b-table
            :fields="tableColumns"
            :items="students"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap"
            hover
            responsive
            no-local-sorting
            @row-clicked="onRowClicked"
            @sort-changed="onSortChange"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(avatar)="data">
              <user-avatar slot="aside" size="md" rounded="false" :user="data.item.user"> </user-avatar>
            </template>

            <template #cell(name)="data"
              ><strong> {{ data.item.user.first_name }} {{ data.item.user.last_name }} </strong></template
            >

            <template #cell(address)="data">
              {{ data.item.address }}, {{ data.item.zipcode }}, {{ data.item.city }}, {{ data.item.state }},
              {{ data.item.country }}
            </template>

            <!-- <template #cell(status)="data">
              <b-badge :class="'border border-' + fetchStudentStatus(data.item).class" variant="none">{{
                fetchStudentStatus(data.item).text
              }}</b-badge>
            </template> -->

            <template #head(actions)="">
              <span></span>
            </template>

            <template #cell(actions)="data">
              <a :href="`mailto:${data.item.user.email}`"
                ><i class="material-icons mr-1" v-b-popover.hover.left title="Email student">email</i></a
              >
              <a class="ml-2" :href="`tel:${data.item.user.phone}`" v-b-popover.hover.left title="Call student"
                ><i class="material-icons mr-1">phone</i></a
              >
            </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalStudents"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="students-table"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import { debounce } from 'lodash';
import { mapActions } from 'vuex';

import Page from '@/components/Page.vue';
import Pagination from '../../../components/Ui/Pagination.vue';
import MdIcon from '../../../components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE } from '../../../common/constants';
import UserAvatar from '../../../components/User/UserAvatar.vue';

export default {
  components: { MdIcon, PageHeader, Pagination, UserAvatar },
  extends: Page,

  data() {
    return {
      title: this.$t('studentMsgs.manageStudents'),

      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalStudents: 0,
      students: [],

      searchTerm: '',
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: this.$t('studentMsgs.students'), active: true },
      ];
    },

    tableColumns() {
      return [
        { key: 'avatar', label: '' },
        { key: 'name', label: this.$t('studentMsgs.name'), tdClass: 'clickable-item' },

        { key: 'user.email', label: this.$t('authMsgs.email'), tdClass: 'clickable-item' },
        { key: 'user.phone', label: this.$t('studentMsgs.phone'), tdClass: 'clickable-item' },

        {
          key: 'actions',
          tdClass: 'text-right',
          thStyle: { minWidth: '100px' },
        },
      ];
    },
  },

  methods: {
    ...mapActions('student', ['getAllStudents']),
    onRowClicked(data) {
      this.$router.push({
        name: 'schl-student-profile',
        params: { id: data.id },
      });
    },

    async fetchStudents(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getAllStudents({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params,
      });
      this.students = response.data.results;
      this.currentPage = pageNum;
      this.totalStudents = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchStudents(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchStudents();
    },

    onSearch() {
      this.debouncedSearchStudents(this);
    },

    debouncedSearchStudents: debounce((vm) => {
      vm.fetchStudents();
    }, 500),
  },

  async mounted() {
    this.fetchStudents();
  },
};
</script>
