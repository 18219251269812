<template>
  <div>
    <page-header :title="title" :container-class="containerClass" :breadcrumb="breadcrumb" />
    <div class="page-section">
      <div :class="containerClass">
        <div class="card mb-0">
          <email-tabs
            :emails="emails"
            :per-page="perPage"
            :total-emails="totalEmails"
            :is-loading="isLoading"
            :email-type="emailType"
            :search-term.sync="searchTerm"
            @onSort="onSortChange"
            @edit="onRowClicked"
            @pageChange="onPageChange"
            @search="onSearch"
            @fetch="fetchEmails"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import { debounce } from 'lodash';
import { mapActions } from 'vuex';

import Page from '@/components/Page.vue';
import { DEFAULT_PAGE_SIZE, EMAIL_TYPES } from '../../../common/constants';

import EmailTabs from '../../SchoolPortal/Emails/EmailTabs.vue';
export default {
  components: { PageHeader, EmailTabs },
  extends: Page,

  data() {
    return {
      title: 'Manage Branding',
      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalEmails: 0,
      emails: [],
      searchTerm: '',
      tabIndex: 0,
      emailType: EMAIL_TYPES.SYSTEM,
    };
  },

  computed: {
    breadcrumb() {
      return [{ text: 'Email Templates', active: true }];
    },
  },

  methods: {
    ...mapActions('emailConfig', ['getAllEmails']),

    onRowClicked(data) {
      this.$router.push({
        name: 'edit-email',
        params: { id: data.id },
      });
    },

    async fetchEmails(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getAllEmails({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...(this.emailType && { email_type: this.emailType }),
        ...params,
      });
      this.emails = response.data.results;
      this.currentPage = pageNum;
      this.totalEmails = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchEmails(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchEmails();
    },

    onSearch() {
      this.debouncedSearchEmails(this);
    },

    debouncedSearchEmails: debounce((vm) => {
      vm.fetchEmails();
    }, 500),
  },

  async mounted() {
    this.fetchEmails();
  },
};
</script>
